// Bootstrap
import 'bootstrap';

// Components
import './components/aos';
import './components/swiper';

// theme misc
import './misc';

// menu navigation active class
import './components/menunav';