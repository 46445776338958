(function() {

    // Add a body class once page has loaded
    // Used to add CSS transitions to elems
    // and avoids content shifting during page load
    window.addEventListener('load', function() {
        document.body.classList.add('page-loaded');        
        var myElement = document.querySelector('#myCarousel .carousel-item').classList.add('active');
    });
})();

